.my-side-bar {
    height: 100vh;
    width: 400px;
    background-color: #fff;
    padding: 30px;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1040;
    box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.1);
    transition: all 500ms ease;
    overflow-y: auto;
}

.my-side-bar.active {
    right: 0 !important;
}

.my-side-bar .fa-xmark {
    font-size: 1.5rem;
}

@media (max-width:575px) {
    .my-side-bar {
        width: 100%;
    }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus-visible {
    background-color: var(--theme_yellow) !important;
    border-color: var(--theme_yellow) !important;
    border-radius: 0 !important;
    font-size: 17px !important;
    color: var(--theme_blue) !important;
}