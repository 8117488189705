.footer-background {
  background: url("../../../images/footer_bg.png");
  background-position: 0 -15%;
  background-repeat: no-repeat;
  padding-top: 100px;
}

.stay-touch {
  padding: 40px 50px;
  background-color: #10296B;
  display: flex;
  align-items: center;
  gap: 70px;
}

.stay-touch h1 {
  font-style: italic;
  font-weight: 500;
  color: #fff;
}

.stay-email-input {
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  color: #fff;
  padding: 6px 0;
}

.stay-email-input::placeholder {
  color: #fff;
}

.stay-email-submit {
  padding: 7px 14px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  width: 97px;
  font-weight: 500;
}

.stay-email-submit:hover {
  background-color: var(--theme_yellow);
  color: var(--theme_blue);
  border-color: var(--theme_yellow);
}

.footer-sec  a {
  color: #000;
}

.footer-sec ul {
  list-style-type: none;
  padding: 0;
}

.footer-sec ul li {
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
}

.footer-sec ul li a {
  display: block;
}

.footer-sec ul li:not(.mb-4, .addr):hover {
  background-color: #1424541f;
  border-radius: 4px;
}

.footer-sec .social i {
  cursor: pointer;
  transition: all 100ms ease;
}

.footer-sec .social i:hover {
  scale: 1.2;
}

@media (min-width:1200px) {
  .footer-background {
      padding-top: 150px;
  }
}

@media (min-width:1500px) {
  .footer-background {
      background-position: unset;
      padding-top: 180px;
  }
}

@media (min-width:1700px) {
  .footer-background {
      background-size: 100%;
      padding-top: 200px;
  }
}

@media (max-width:991px) {
  .stay-touch {
      flex-direction: column;
      align-items: start;
      gap: 30px;
  }

  .stay-email-input {
      width: auto;
  }

  .footer-background {
      background: #eef2f6 !important;
  }
}