:root {
  --100: #ccffff;
  --200: #99ffff;
  --300: #66ffff;
  --400: #33ffff;
  --500: #00ffff;
  --600: #142454;
  --700: #142454;
  --800: #006666;
  --900: #003333;
  --101: #f5ccf5;
  --201: #eb99eb;
  --301: #e066e0;
  --401: #d633d6;
  --501: #cc00cc;
  --601: #a300a3;
  --701: #7a007a;
  --801: #520052;
  --901: #290029;
}

.primary_main {
  color: var(--600);
}

.secondary_main {
  color: var(--601);
}

a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

p,
h4 {
  margin: 0;
}

/* span[title="View code"] {
  display: none !important;
} */

/* .gjs-pn-buttons:nth-child(6) {
  display: none !important;
} */

.gjs-one-bg {
  background-color: #142454 !important;
}

body {
  background-color: #fff !important;
}