.login_image {
  animation: animate-user 2s ease-in-out infinite;
}

@keyframes animate-user {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
li {
  list-style-type: none;
}
