/* header {
  width: 100%;
  height: 80px;
  background: #343062;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
}

header .logo {
  font-size: 28px;
  font-weight: bold;
  color: #fefefe;
}

.hamburger {
  display: none;
}

.nav-bar ul {
  display: flex;
}

.nav-bar ul li a {
  display: block;
  color: #fefefe;
  font-size: 20px;
  padding: 10px 25px;
  border-radius: 50px;
  transition: 0.2s;
  margin: 0 5px;
}

.nav-bar ul li a:hover {
  color: #11101b;
  background: #fefefe;
}

.nav-bar ul li a.active {
  color: #11101b;
  background: #fefefe;
}

@media only screen and (max-width: 1320px) {
  header {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1100px) {
  header {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 768px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .hamburger .line {
    width: 30px;
    height: 3px;
    background: #fefefe;
    margin: 6px 0;
  }

  .nav-bar {
    height: 0px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100vw;
    background: #68658e;
    transition: 0.2s;
    overflow: hidden;
  }

  .nav-bar.active {
    height: 450px;
  }

  .nav-bar ul {
    display: block;
    width: fit-content;
    margin: 80px auto 0 auto;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
  }

  .nav-bar.active ul {
    opacity: 1;
  }

  .nav-bar ul li a {
    margin-bottom: 12px;
  }
} */

/* .gjs-scroll-container {
  min-height: 10px !important;
}
.gjs-scroll-inactive {
  position: relative;
  transform: translateY(17px);
} */
* {
  font-family: "Alkatra", cursive;
}

.my_nav_fixed a {
  color: #fff !important;
  padding: 7px 15px;
  cursor: pointer;
}

.my_nav_fixed .nav-link .active {
  border-bottom: 2px solid #fff;
}

.transf_y {
  transform: translateY(40px);
  transition: 0.2s linear !important;
}

.transf_y_none {
  transform: translateY(0px);
  transition: 0.2s linear !important;
}

.active {
  border-bottom: 2px solid #fff;
}

.bg_blue {
  background-color: #10296b;
}

.navbar-toggler {
  border: 2px solid #fff !important;
  background-color: #ddd !important;
}

@media (max-width:991px) {
  .navbar {
    background-color: var(--theme_blue);
  }

  .navbar-collapse {
    border-bottom: 2px solid #edf155;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .my_nav_fixed a {
    padding: 10px 4px;
  }
}

@media (max-width:767px) {
  .transf_y {
    transform: translateY(20px);
  }
}

@media (max-width:575px) {
  .transf_y {
    transform: translateY(10px);
  }
}

.navbar-toggler:focus,
.navbar-toggler:focus-visible {
  border: none;
  box-shadow: none;
}

.navbar-toggler {
  color: #fff;
  font-size: 28px;
  background-color: transparent !important;
  border: none !important;
}

.navbar-nav a {
  font-weight: 500 !important;
  border-bottom: 2px solid transparent;
}

@media (min-width:992px) {
  .navbar-nav a:hover {
    border-color: #fff;
  }
}