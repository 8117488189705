li{
    list-style-type: none !important;
}

*{
    font-family: 'Amethysta', serif;
    font-family: 'Poppins', sans-serif;
}


